import { useMutation } from '@tanstack/react-query';
import {
  getCurrentGptModel,
  syncStore,
  useSyncedProjectId
} from 'features/aiWriter/AiWriterSidebar/steps/chat/chatStore';
import { invalidateActiveConversationQuery } from 'features/aiWriter/AiWriterSidebar/steps/chat/useActiveConversationQuery';
import {
  GptModel,
  httpCreateConversation
} from 'services/backofficeIntegration/http/endpoints/aiWriter/httpCreateConversation';
import { createUuidV4 } from 'utils/createUuidV4';

export const useResetConversationMutation = () => {
  const projectId = useSyncedProjectId();

  return useMutation({
    mutationFn: async ({
      personalityId,
      brandVoiceId,
      gptModel
    }: {
      personalityId: number | null;
      brandVoiceId: string | null;
      gptModel: GptModel | null;
    }) => {
      const conversationId = createUuidV4();

      return httpCreateConversation.callEndpoint({
        projectId,
        conversationId,
        personalityId,
        brandVoiceId,
        gptModel
      });
    },
    onSuccess: (data, { personalityId, brandVoiceId, gptModel }) => {
      if (!data) return;
      const { conversation_id: conversationId } = data;
      const currentGptModel = getCurrentGptModel();
      invalidateActiveConversationQuery({ projectId });

      syncStore({
        projectId,
        conversationId,
        personalityId,
        brandVoiceId,
        gptModel: gptModel ?? currentGptModel ?? null,
        suggestions: []
      });
    }
  });
};
