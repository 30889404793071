import { Avatar } from '@mui/material';
import styled from 'styled-components';

type Props = {
  thumbnailUrl?: string;
};

export const BrandVoiceThumbnailAvatar = ({ thumbnailUrl }: Props) => (
  <StyledAvatar $hasThumbnail={!!thumbnailUrl} src={thumbnailUrl} />
);

const StyledAvatar = styled(Avatar)<{ $hasThumbnail: boolean }>`
  width: 24px;
  height: 24px;
  border: ${({ $hasThumbnail, theme }) =>
    $hasThumbnail ? `1px solid ${theme.colors.commonBlackFocusvisible}` : 'none'};
`;
