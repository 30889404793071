import {
  Message,
  MessageDraft,
  MessageSource
} from 'features/aiWriter/AiWriterSidebar/steps/chat/Message';
import {
  GPT_MODELS,
  GptModel
} from 'services/backofficeIntegration/http/endpoints/aiWriter/httpCreateConversation';
import { Suggestion } from 'services/backofficeIntegration/http/endpoints/aiWriter/httpCreateSuggestions';
import { PartialMessageDto } from 'services/backofficeIntegration/http/endpoints/aiWriter/httpSendMessage';
import { InformationDto } from 'services/backofficeIntegration/http/endpoints/infomration/httpGetInformationList';
import { createUuidV4 } from 'utils/createUuidV4';
import { createStore, useStore } from 'zustand';
import { shallow } from 'zustand/shallow';
import { useStoreWithEqualityFn } from 'zustand/traditional';

type State = {
  projectId: string | null;
  conversationId: string | null;
  personalityId: number | null;
  brandVoiceId: string | null;
  gptModel: GptModel | null;
  informationList: InformationDto[];
  suggestions: Suggestion[];
  /**
   * The message that was sent but not yet fetched from messages endpoint
   */
  optimisticQuestion: Message | null;
  /**
   * Response is streamed in chunks so we could display it as soon as possible
   * We store a streamed response here as long as it is not fetched from messages endpoint
   */
  streamedResponse: Message | null;
  /**
   * These are the messages that we get from messages endpoint
   */
  confirmedMessages: Message[];
  /**
   * These are the messages that we fetched previously but after new messages
   * where added they where not fetched because of the pagination
   */
  staleMessages: Message[];
  messageDraft: MessageDraft;
  /**
   * Marks if stored message should be kept after store is synced
   */
  shouldKeepMessage: boolean;
  instantSendingEnabled: boolean;
};

const initialState: State = {
  projectId: null,
  conversationId: null,
  personalityId: null,
  brandVoiceId: null,
  optimisticQuestion: null,
  streamedResponse: null,
  confirmedMessages: [],
  staleMessages: [],
  messageDraft: createMessageDraft(),
  shouldKeepMessage: false,
  instantSendingEnabled: false,
  gptModel: GPT_MODELS.GPT_3_5,
  suggestions: [],
  informationList: []
};

const chatStore = createStore<State>(() => ({
  ...initialState
}));

/**
 * Order of messages is from newest to oldest
 */
function selectAllMessages(state: State) {
  const messages: Message[] = [];
  if (state.optimisticQuestion) {
    messages.push(state.optimisticQuestion);
  }
  messages.push(...state.confirmedMessages, ...state.staleMessages);
  return messages;
}

export function useStreamedResponse(): Message | null {
  return useStore(chatStore, state => state.streamedResponse);
}

export function useStoredMessages() {
  return useStoreWithEqualityFn(chatStore, state => selectAllMessages(state), shallow);
}

export function useIsLastMessage(message: Message) {
  const messages = useStoredMessages();
  // this check is needed because there can be a race condition due to state updates in chat store
  if (messages.length === 0) return false;
  // the last message is the first entry in an array in a LIFO manner
  return messages[0].id === message.id;
}

export function useIsChatEmpty() {
  const messages = useStoredMessages();
  return messages.length === 0;
}

export function useStoredProjectId() {
  return useStore(chatStore, state => state.projectId);
}

export function useSyncedProjectId(): string {
  const projectId = useStoredProjectId();
  if (!projectId) {
    throw new Error('missing projectId');
  }

  return projectId;
}

export function useStoredActiveConversationId() {
  return useStore(chatStore, state => state.conversationId);
}

export function useMessageDraft(): MessageDraft {
  return useStore(chatStore, state => state.messageDraft);
}

export function useShouldSendMessageInstantly() {
  return useStore(chatStore, state => state.instantSendingEnabled);
}

export function useStoredPersonalityId() {
  return useStore(chatStore, state => state.personalityId);
}

export function useStoredBrandVoiceId() {
  return useStore(chatStore, state => state.brandVoiceId);
}

export function savePersonalityId(personalityId: number | null) {
  chatStore.setState({
    personalityId
  });
}

export function saveBrandVoiceId(brandVoiceId: string | null) {
  chatStore.setState({
    brandVoiceId
  });
}

export function setInformationList(informationList: InformationDto[]) {
  chatStore.setState({
    informationList
  });
}

export function useIsProjectSynced(projectId: string) {
  const storedId = useStoredProjectId();

  return projectId === storedId;
}

export function useIsWaitingForResponse() {
  return useStore(
    chatStore,
    state =>
      !!state.optimisticQuestion &&
      (!state.streamedResponse || state.streamedResponse.text.length < 1)
  );
}

export function syncStore({
  projectId,
  conversationId,
  personalityId,
  brandVoiceId,
  gptModel,
  suggestions,
  informationList
}: {
  projectId: string;
  conversationId: string | null;
  personalityId: number | null;
  brandVoiceId: string | null;
  gptModel?: GptModel | null;
  suggestions?: Suggestion[];
  informationList?: InformationDto[];
}) {
  chatStore.setState(old => {
    const stateToKeep: Partial<State> = old.shouldKeepMessage
      ? {
          messageDraft: old.messageDraft,
          instantSendingEnabled: old.instantSendingEnabled
        }
      : {};

    return {
      ...initialState,
      personalityId,
      brandVoiceId,
      projectId,
      conversationId,
      gptModel,
      suggestions,
      informationList,
      ...stateToKeep
    };
  });
}

export function updateActiveConversation(props: { conversationId: string | null }) {
  const { conversationId } = props;

  chatStore.setState({
    conversationId
  });
}

export function disableInstantSending() {
  chatStore.setState({
    instantSendingEnabled: false
  });
}

export function changeMessageDraft(props: { text: string }) {
  const { text } = props;
  chatStore.setState(old => ({
    messageDraft: {
      ...old.messageDraft,
      text
    }
  }));
}

function createMessageDraft(text = ''): MessageDraft {
  return {
    id: createUuidV4(),
    text,
    source: MessageSource.user,
    failedToSend: false
  };
}

export function newMessageDraft(props: { text?: string; sendInstantly?: boolean } = {}) {
  const { text = '', sendInstantly = false } = props;
  chatStore.setState({
    messageDraft: createMessageDraft(text),
    instantSendingEnabled: sendInstantly
  });
}

export function predefineMessageDraft({
  text,
  personalityId,
  brandVoiceId,
  gptModel,
  informationList
}: {
  text: string;
  personalityId: number | null;
  brandVoiceId: string | null;
  gptModel?: GptModel;
  informationList?: InformationDto[];
}) {
  chatStore.setState({
    ...initialState,
    messageDraft: createMessageDraft(text),
    personalityId: personalityId ?? null,
    brandVoiceId: brandVoiceId ?? null,
    gptModel: gptModel ?? null,
    shouldKeepMessage: true,
    instantSendingEnabled: true,
    informationList: informationList
  });
}

export function getStaleMessages() {
  return chatStore.getState().staleMessages;
}

export function getCurrentGptModel() {
  return chatStore.getState().gptModel;
}

export const useChatCurrentGptModel = () => useStore(chatStore, state => state.gptModel);

export function getCurrentSuggestions() {
  return chatStore.getState().suggestions;
}

export function getInformationList() {
  return chatStore.getState().informationList;
}

export function saveCurrentSuggestions(suggestions: Suggestion[]) {
  chatStore.setState({
    suggestions
  });
}

export function updateStoredMessages(props: { messages: Message[] }) {
  const { messages } = props;
  chatStore.setState(old => {
    const newIds = new Set(messages.map(m => m.id));
    const optimisticQuestion =
      !old.optimisticQuestion || newIds.has(old.optimisticQuestion.id)
        ? null
        : old.optimisticQuestion;
    const streamedResponse =
      !old.streamedResponse || newIds.has(old.streamedResponse.id) ? null : old.streamedResponse;
    const staleMessages = old.confirmedMessages.filter(om => !newIds.has(om.id));
    const messageDraft = newIds.has(old.messageDraft.id) ? createMessageDraft() : old.messageDraft;
    return {
      optimisticQuestion,
      streamedResponse,
      confirmedMessages: messages,
      staleMessages,
      messageDraft
    };
  });
}

export function addOptimisticQuestion(message: Message) {
  chatStore.setState({
    optimisticQuestion: message,
    streamedResponse: null,
    messageDraft: createMessageDraft()
  });
}

function extractFailedDraft(message: Message): MessageDraft {
  const { id, source, text } = message;
  return { id, source, text, failedToSend: true };
}

export function revertOptimisticQuestion() {
  chatStore.setState(old => {
    if (!old.optimisticQuestion) return old;

    return {
      optimisticQuestion: null,
      streamedResponse: null,
      messageDraft: extractFailedDraft(old.optimisticQuestion)
    };
  });
}

export function updateStreamedResponse(chunk: string) {
  chatStore.setState(old => {
    if (!old.streamedResponse) return old;
    return {
      streamedResponse: { ...old.streamedResponse, text: old.streamedResponse.text + chunk }
    };
  });
}

export function addStreamedResponse(partialMessage: PartialMessageDto) {
  chatStore.setState({
    streamedResponse: { ...partialMessage, text: '' }
  });
}

export function dropStaleMessages() {
  chatStore.setState({
    staleMessages: []
  });
}

export function resetChatStore() {
  chatStore.setState(initialState);
}
