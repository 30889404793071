import { ChevronLeft, LabelRounded, MoreVert } from '@mui/icons-material';
import { ListItemIcon, MenuItem, Tooltip, Typography } from '@mui/material';
import { MenuDropdown } from 'components/MenuDropdown/MenuDropdown';
import { PersonalityIcon } from 'components/personality/PersonalityIcon';
import { topBarDropdownAlignmentConfig } from 'features/aiWriter/AiWriterTextEditor/utils/topBarDropdownAlignmentConfig';
import { LanguageMenu } from 'features/aiWriter/AiWriterWorkspace/header/LanguageMenu';
import { DocumentTopBarBrandVoiceButtonMenu } from 'features/brand-voice/DocumentTopBarBrandVoiceButtonMenu';
import {
  CreateIcon,
  DocumentPageTopBarDocument,
  popoverProps,
  SmallerCountryFlag,
  StyledIconButton
} from 'features/document-top-bar/DocumentPageTopBar';
import { PersonalityButton } from 'features/document-top-bar/PersonalityButton';
import { TagsButton } from 'features/document-top-bar/TagsButton';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import { FormattedMessage } from 'react-intl';
import gtmIds from 'services/tracking/GTMIds';
import { withGtmInteraction } from 'services/tracking/withGtmInteraction';
import styled from 'styled-components';
import useDropdown from 'utils/hooks/useDropdown';
import { useMenu } from 'utils/hooks/useMenu';

type Props = {
  onBack: () => void;
  onCreate?: () => void;
  withLanguageChange?: boolean;
  activeDocument: DocumentPageTopBarDocument;
  hideBackButton?: boolean;

  enablePersonalityButton: boolean;
  enableTagsButton: boolean;
};

export const DocumentPageTopBarMoreButton = (props: Props) => {
  const {
    onBack,
    onCreate,
    withLanguageChange,
    activeDocument,
    enablePersonalityButton = false,
    enableTagsButton = false,
    hideBackButton = false
  } = props;
  const { anchorElement, isOpen, open, close } = useDropdown();
  const {
    anchorEl: languageAnchorEl,
    isOpen: isLanguageMenuOpen,
    onTriggerClick: onLanguageTriggerClick,
    onMenuClose: onLanguageMenuClose
  } = useMenu();

  const isBrandHubEnabled = useFeatureFlagEnabled('new-brand-hub-in-sidebar');

  const handleCreateDocument = () => {
    close();
    onCreate?.();
  };

  const handleGoBack = () => {
    close();
    onBack();
  };

  const handleCloseLanguageMenu = () => {
    onLanguageMenuClose();
    close();
  };

  return (
    <>
      <MenuDropdown
        trigger={
          <Tooltip arrow title={<FormattedMessage id="aiWriter.toolbar.more" />} placement="bottom">
            <StyledIconButton onClick={open}>
              <MoreVert fontSize="small" />
            </StyledIconButton>
          </Tooltip>
        }
        isOpen={isOpen}
        anchorElement={anchorElement}
        close={close}
        anchorOrigin={topBarDropdownAlignmentConfig.leftAlignment.anchorOrigin}
        transformOrigin={topBarDropdownAlignmentConfig.leftAlignment.transformOrigin}
        width="auto"
        sx={{ padding: '0.5rem' }}
      >
        {!hideBackButton && (
          <MenuItem {...withGtmInteraction(gtmIds.aiWriter.backToOverview)} onClick={handleGoBack}>
            <ListItemIcon>
              <ChevronLeft />
            </ListItemIcon>
            <Typography>
              <FormattedMessage id="aiWriter.topbar.tooltip.back_to_overview" />
            </Typography>
          </MenuItem>
        )}

        {onCreate && (
          <MenuItem id={gtmIds.aiWriter.editorCreateNewProject} onClick={handleCreateDocument}>
            <ListItemIcon>
              <CreateIcon />
            </ListItemIcon>
            <Typography>
              <FormattedMessage id="aiWriter.topbar.tooltip.new_document" />
            </Typography>
          </MenuItem>
        )}

        {activeDocument.modelData && (
          <MenuItem
            {...withGtmInteraction(gtmIds.aiWriter.languageSelector)}
            onClick={withLanguageChange ? onLanguageTriggerClick : undefined}
          >
            <ListItemIcon>
              <SmallerCountryFlag
                country={activeDocument.modelData.country}
                language={activeDocument.modelData.language}
              />
            </ListItemIcon>

            <Typography>
              <FormattedMessage id="aiWriter.topbar.tooltip.change_language" />
            </Typography>
          </MenuItem>
        )}
        {enablePersonalityButton &&
          (isBrandHubEnabled ? (
            <DocumentTopBarBrandVoiceButtonMenu
              onSelect={close}
              dropDownProps={{
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'right'
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'left'
                }
              }}
            >
              {onTriggerClick => (
                <MenuItem onClick={onTriggerClick}>
                  <ListItemIcon>
                    <PersonalityIcon />
                  </ListItemIcon>
                  <Typography>
                    <FormattedMessage id="common.brandVoice" />
                  </Typography>
                </MenuItem>
              )}
            </DocumentTopBarBrandVoiceButtonMenu>
          ) : (
            <PersonalityButton
              onSelect={close}
              dropDownProps={{
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'right'
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'left'
                }
              }}
            >
              {onPersonalityIconClick => (
                <MenuItem onClick={onPersonalityIconClick}>
                  <ListItemIcon>
                    <PersonalityIcon />
                  </ListItemIcon>
                  <Typography>
                    <FormattedMessage id="common.brandVoice" />
                  </Typography>
                </MenuItem>
              )}
            </PersonalityButton>
          ))}

        {enableTagsButton && (
          <TagsButton onClose={close}>
            {handleOpenTagsDropdown => (
              <MenuItem onClick={handleOpenTagsDropdown}>
                <ListItemIcon>
                  <TagsIcon fontSize="small" />
                </ListItemIcon>

                <Typography>
                  <FormattedMessage id="common.tags" />
                </Typography>
              </MenuItem>
            )}
          </TagsButton>
        )}
      </MenuDropdown>
      <LanguageMenu
        anchorEl={languageAnchorEl}
        open={isLanguageMenuOpen}
        onClose={handleCloseLanguageMenu}
        {...popoverProps}
        isSaveAsDefaultShown={true}
      />
    </>
  );
};

const TagsIcon = styled(LabelRounded)`
  color: ${({ theme }) => theme.colors.componentsIconDefault};
`;
