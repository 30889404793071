import Toast from 'components/toasts/Toast';
import { getErrorCodeFromAxiosError } from 'features/teamInvitation/getErrorCodeFromAxiosError';
import { Elements } from 'features/workflowBlogPostBuilder/types';
import { handleGenerateTextErrorsWithHyperlink } from 'services/api/wordEmbedding/errors';
import { BaseSuccessDto } from 'services/backofficeIntegration/http/BaseSuccessDto';
import { backofficeClient } from 'services/backofficeIntegration/http/client/createBackofficeClient';
import { mutationEndpoint } from 'services/backofficeIntegration/http/endpointDefinition';

export type GenerateBlogPostParams = {
  title: string;
  outline: string[];
  elements: Elements[];
  keywords: string;
  language: string;
  country: string;
  tonality: string[];
  audience_model_id: string;
  personality_id: number | null;
  brand_voice_id?: string;
  information_ids?: number[];
};

type SuccessDto = BaseSuccessDto<BlogPostDto>;

export type BlogPostDto = {
  intro: string;
  youtube_video: string[];
  external_links: string[];
  outline: OutlineItem[];
  questions: QuestionItem[];
};

export type OutlineItem = {
  headline: string;
  paragraph: string;
};

export type QuestionItem = {
  questions: string;
  answer: string;
};

export const httpGenerateBlogPost = mutationEndpoint({
  callEndpoint: (params: GenerateBlogPostParams) =>
    backofficeClient
      .post<SuccessDto>('/workflow/blog-post', params)
      .then(response => {
        if (response.data.message === 'PARTIAL_SUCCESS') {
          Toast.warning('blog_post_builder.partial_error');
        }

        return response.data.data;
      })
      .catch(error => {
        const errorCode = getErrorCodeFromAxiosError(error);
        if (errorCode) {
          Toast.backendError(...handleGenerateTextErrorsWithHyperlink(errorCode));
        } else {
          Toast.error('blog_post_builder.error');
        }

        return error.data.data;
      })
});
