import LocalStorageKey from 'config/localStorageKey';
import {
  getCurrentGptModel,
  getInformationList,
  syncStore,
  useIsProjectSynced,
  useShouldSendMessageInstantly,
  useStoredActiveConversationId,
  useStoredBrandVoiceId,
  useStoredPersonalityId
} from 'features/aiWriter/AiWriterSidebar/steps/chat/chatStore';
import { useActiveConversationQuery } from 'features/aiWriter/AiWriterSidebar/steps/chat/useActiveConversationQuery';
import { useDefaultPersonalityQuery } from 'features/aiWriter/AiWriterSidebar/steps/chat/useDefaultPersonalityQuery';
import { getActiveTab } from 'features/aiWriter/store/selectors';
import { useGetDefaultBrandVoice } from 'features/brand-voice/useGetDefaultBrandVoice';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import { useLayoutEffect, useMemo } from 'react';
import { GptModel } from 'services/backofficeIntegration/http/endpoints/aiWriter/httpCreateConversation';
import { InformationDto } from 'services/backofficeIntegration/http/endpoints/infomration/httpGetInformationList';
import { useAppSelector } from 'store/hooks';

export function useStoreSyncGuard(props: { projectId: string }) {
  const { projectId } = props;
  const projectSynced = useIsProjectSynced(projectId);
  const storedConversationId = useStoredActiveConversationId();
  const storedPersonalityId = useStoredPersonalityId() ?? null;
  const storedBrandVoiceId = useStoredBrandVoiceId() ?? null;

  const isBrandHubEnabled = useFeatureFlagEnabled('new-brand-hub-in-sidebar');

  const tab = useAppSelector(getActiveTab);
  const { personalityId: documentPersonalityId, brandVoiceId: documentBrandVoiceMaybeUndefined } =
    tab.generateTextConfig;

  // This is bugfix to prevent infinite store sync while brand voice 2.0 is NOT enabled
  // The stored brand voice is null, the document brand voice is undefined (therefore not equal)
  // Yes, the best bugfix would be to prevent document brand voice to being undefined..
  const documentBrandVoiceId = documentBrandVoiceMaybeUndefined ?? null;

  const storedGptModel = getCurrentGptModel();
  const activeConversationResult = useActiveConversationQuery({ projectId });
  const defaultPersonalityResult = useDefaultPersonalityQuery();
  const defaultBrandVoiceResult = useGetDefaultBrandVoice();
  const storedInformationList = getInformationList();
  const instantSendingEnabled = useShouldSendMessageInstantly();

  const activeConversationId = activeConversationResult.data?.conversation_id ?? null;
  const activePersonalityId = activeConversationResult.data?.personality_id ?? null;
  const activeBrandVoiceId = activeConversationResult.data?.brand_voice_id ?? null;
  const defaultPersonalityId = defaultPersonalityResult.data?.id ?? null;
  const defaultBrandVoiceId = defaultBrandVoiceResult.data?.brandVoiceId ?? null;
  const activeInformationList = activeConversationResult.data?.informations ?? [];

  let personalityId = defaultPersonalityId;
  let brandVoiceId = defaultBrandVoiceId;

  if (activeConversationId) {
    personalityId = activePersonalityId;
  } else if (documentPersonalityId) {
    personalityId = documentPersonalityId;
  } else if (instantSendingEnabled) {
    /*
     * Here is the case when message comes from an overview page as a message draft
     */
    personalityId = storedPersonalityId;
  }

  if (activeConversationId) {
    brandVoiceId = activeBrandVoiceId;
  } else if (documentBrandVoiceId) {
    brandVoiceId = documentBrandVoiceId;
  } else if (instantSendingEnabled) {
    brandVoiceId = storedBrandVoiceId;
  }

  let personalitySynced: boolean;
  let brandVoiceSynced: boolean;
  if (activeConversationId) {
    personalitySynced = activePersonalityId === storedPersonalityId;
    brandVoiceSynced = activeBrandVoiceId === storedBrandVoiceId;
  } else if (documentPersonalityId) {
    personalitySynced = documentPersonalityId === storedPersonalityId;
    brandVoiceSynced = documentBrandVoiceId === storedBrandVoiceId;
  } else if (instantSendingEnabled) {
    personalitySynced = true;
    brandVoiceSynced = true;
  } else {
    personalitySynced = defaultPersonalityId === storedPersonalityId;
    brandVoiceSynced = defaultBrandVoiceId === storedBrandVoiceId;
  }

  const localStorageGptModel = localStorage.getItem(LocalStorageKey.DefaultGptModel);

  let activeGptModel: GptModel | null | undefined = storedGptModel;

  if (activeConversationId) {
    activeGptModel = activeConversationResult.data?.current_model;
  } else if (localStorageGptModel) {
    activeGptModel = localStorageGptModel as GptModel;
  }

  const gptModelSynced = activeGptModel === storedGptModel;

  const chatSuggestions = useMemo(
    () => activeConversationResult.data?.suggestions ?? [],
    [activeConversationResult.data?.suggestions]
  );

  let informationList: InformationDto[];
  let informationSynced;

  if (activeInformationList.length !== 0) {
    informationList = activeInformationList;
    informationSynced = true;
  } else if (instantSendingEnabled && storedInformationList?.length !== 0) {
    /**
     * Here is the case when message comes from an overview page as a message draft
     */
    informationList = storedInformationList;
    informationSynced = true;
  } else {
    informationList = [];
    informationSynced = true;
  }

  /**
   * We assume that that conversation is in sync while query is fetching. This
   * is important in case when first message is added to new conversation. In
   * that case instead of updating the store, we should wait for query to
   * refetch the data.
   */

  const conversationSynced =
    activeConversationResult.isFetching || activeConversationId === storedConversationId;

  const storeSynced =
    projectSynced &&
    conversationSynced &&
    personalitySynced &&
    brandVoiceSynced &&
    gptModelSynced &&
    informationSynced;

  /**
   * We wait for query to finish fetching so we don't have to sync data twice:
   * 1) for project mismatch, 2) for conversation mismatch
   */
  const shouldSync =
    !storeSynced &&
    !activeConversationResult.isFetching &&
    !defaultPersonalityResult.isFetching &&
    !defaultBrandVoiceResult.isFetching;

  const isSyncing =
    activeConversationResult.isLoading ||
    /**
     * We must wait until store is in sync to display anything. Otherwise, we
     * may display data related to different conversation.
     */
    !storeSynced;

  useLayoutEffect(() => {
    if (shouldSync) {
      syncStore({
        projectId,
        conversationId: activeConversationId,
        personalityId,
        brandVoiceId,
        gptModel: activeGptModel,
        suggestions: chatSuggestions,
        informationList
      });
    }
  }, [
    shouldSync,
    projectId,
    activeConversationId,
    activePersonalityId,
    defaultPersonalityId,
    personalityId,
    activeBrandVoiceId,
    defaultBrandVoiceId,
    brandVoiceId,
    activeGptModel,
    chatSuggestions,
    informationList
  ]);

  return {
    isSyncing,
    isError:
      activeConversationResult.isError ||
      (!isBrandHubEnabled && defaultPersonalityResult.isError) ||
      (!!isBrandHubEnabled && defaultBrandVoiceResult.isError),
    refetchFailed: () => {
      if (activeConversationResult.isError) {
        activeConversationResult.refetch();
      }
      if (!isBrandHubEnabled && defaultPersonalityResult.isError) {
        defaultPersonalityResult.refetch();
      }
      if (!!isBrandHubEnabled && defaultBrandVoiceResult.isError) {
        defaultBrandVoiceResult.refetch();
      }
    }
  };
}
