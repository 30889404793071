import {
  brandVoiceIdField,
  BriefConfigValues,
  informationField,
  keywords2Field,
  keywordsField,
  outputTypeField,
  personalityIdField,
  textField
} from 'features/aiTester/modals/briefConfig/BriefConfigModal';
import { InformationField } from 'features/aiTester/modals/briefConfig/InformationField';
import { PersonalityField } from 'features/aiTester/modals/briefConfig/PersonalityField';
import useHandleOutputTypeChange from 'features/aiTester/modals/briefConfig/useHandleOutputTypeChange';
import { getCurrentModelLanguageAndCountry } from 'features/aiTester/store/selectors';
import { BrandVoiceAutocompleteField } from 'features/brand-voice/BrandVoiceAutocompleteField';
import AdvancedSettingsButton from 'features/textGenerator/AdvancedSettingsButton';
import BriefField from 'features/textGenerator/inputs/BriefField';
import KeywordsField from 'features/textGenerator/inputs/KeywordsField';
import OutputTypeAutocomplete from 'features/textGenerator/OutputTypeAutocomplete/OutputTypeAutocomplete';
import { useFormikContext } from 'formik';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

export const GoalConfigStep = () => {
  const { currentModelLanguage, currentModelCountry } = useSelector(
    getCurrentModelLanguageAndCountry
  );
  const isBrandHubEnabled = useFeatureFlagEnabled('new-brand-hub-in-sidebar');

  const {
    values: { outputType, personalityId, informationList }
  } = useFormikContext<BriefConfigValues>();

  const onOutputTypeChange = useHandleOutputTypeChange();

  const shouldAdvancedOptionsBeExpanded = !!personalityId || !!informationList;

  return (
    <Content>
      <OutputTypeAutocomplete
        name={outputTypeField}
        modelLanguage={currentModelLanguage}
        modelCountry={currentModelCountry}
        onSelect={onOutputTypeChange}
      />
      <BriefField
        name={textField}
        modelLanguage={currentModelLanguage}
        modelCountry={currentModelCountry}
        outputType={outputType}
      />
      <KeywordsField
        name={keywords2Field}
        type="keywords2"
        modelLanguage={currentModelLanguage}
        modelCountry={currentModelCountry}
        outputType={outputType}
      />
      <KeywordsField
        name={keywordsField}
        type="keywords"
        modelLanguage={currentModelLanguage}
        modelCountry={currentModelCountry}
        outputType={outputType}
      />
      <AdvancedSettingsButton isExpanded={shouldAdvancedOptionsBeExpanded}>
        {isBrandHubEnabled ? (
          <BrandVoiceAutocompleteField
            name={brandVoiceIdField}
            currentLanguage={currentModelLanguage}
            currentCountry={currentModelCountry}
          />
        ) : (
          <PersonalityField name={personalityIdField} />
        )}
        <InformationField name={informationField} />
      </AdvancedSettingsButton>
    </Content>
  );
};

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacings.medium};
`;
