import { Button, TextField, Typography } from '@mui/material';
import FlexContainer from 'components/FlexContainer';
import { wiggleAnimation } from 'components/tour/utils/wiggleAnimation';
import { AiWriterSidebarPanel } from 'features/aiWriter/AiWriterSidebar/sidebar/AiWriterSidebarPanel';
import { NumberOfSuggestionsChips } from 'features/aiWriter/AiWriterSidebar/steps/textInspirations/NumberOfSuggestionsChips';
import { ProjectBrandVoiceAutocomplete } from 'features/aiWriter/AiWriterSidebar/steps/textInspirations/ProjectBrandVoiceAutocomplete';
import { ProjectInformationSelection } from 'features/aiWriter/AiWriterSidebar/steps/textInspirations/ProjectInformationSelection';
import { ProjectPersonalitySelection } from 'features/aiWriter/AiWriterSidebar/steps/textInspirations/ProjectPersonalitySelection';
import { TextInspirationsStepHeader } from 'features/aiWriter/AiWriterSidebar/steps/textInspirations/TextInspirationsStepHeader';
import {
  TextLength,
  TextLengthChips
} from 'features/aiWriter/AiWriterSidebar/steps/textInspirations/TextLengthChips';
import useHandleBriefOutputTypeChange from 'features/aiWriter/AiWriterSidebar/steps/textInspirations/useHandleBriefOutputTypeChange';
import useInitialBriefValues from 'features/aiWriter/AiWriterSidebar/steps/textInspirations/useInitialBriefValues';
import { isAiWriterSupportedLanguageAndCountryCode } from 'features/aiWriter/aiWriterSupportedLanguageAndCountryCodes';
import {
  changeGenerateTextConfig,
  setInspirationsCurrentStep
} from 'features/aiWriter/store/actions/config/actions';
import { generateTextInspirationsThunk } from 'features/aiWriter/store/actions/inspirations/thunks/generateTextInspirationsThunk';
import { setIsAiWriterTourFinished } from 'features/aiWriter/store/actions/tour/actions';
import {
  getCurrentModelLanguageAndCountry,
  getIsAiWriterTourRunning,
  getIsGenerateConfigReady
} from 'features/aiWriter/store/selectors';
import { AiWriterSidebarStep } from 'features/aiWriter/store/types';
import { SystemTonality } from 'features/aiWriter/tonality/SystemTonality';
import { TonalitySelect } from 'features/aiWriter/tonality/TonalitySelect';
import { tonalityMaxLength } from 'features/aiWriter/utils/tonalityMaxLength';
import { useCompleteTask } from 'features/onboardingJourney/checklist/useOnboardingChecklist';
import {
  useShouldShowWordsLimitReachedModal,
  useShowWordsLimitReachedModal
} from 'features/pricing/hook/useYouRunOutOfWordsModal';
import AdvancedSettingsButton from 'features/textGenerator/AdvancedSettingsButton';
import { EnhancedOutputTypeAutocomplete } from 'features/textGenerator/EnhancedOutputTypeAutocomplete';
import { TextTypesButton } from 'features/textGenerator/EnhancedOutputTypeAutocomplete/TextTypesButton';
import BriefField from 'features/textGenerator/inputs/BriefField';
import KeywordsField from 'features/textGenerator/inputs/KeywordsField';
import { useShowOutputTypeModal } from 'features/textGenerator/outputTypeModal/useShowOutputTypeModal';
import { maxTextItemCount } from 'features/textGenerator/utils/getNTextItems';
import { mkBriefFormValidationSchema } from 'features/textGenerator/utils/mkBriefFormValidation';
import { Form, Formik } from 'formik';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { TASK_TYPE } from 'services/backofficeIntegration/http/endpoints/onboardingChecklist/httpGetChecklist';
import gtmIds from 'services/tracking/GTMIds';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import styled from 'styled-components';
import useTr from 'utils/hooks/useTr';

const outputTypesForTextLength = [
  'banner_ad',
  'blog_conclusion',
  'blog_intro',
  'bullets_to_paragraph_manual',
  'freestyle',
  'h2_to_paragraph',
  'headlines',
  'product_description_amazon',
  'product_description_descriptive'
];

export const outputTypeField = 'outputType';
export const textField = 'text';
export const keywordsField = 'keywords';
export const keywords2Field = 'keywords2';
export const systemTonalityField = 'systemTonality';
export const userTonalityField = 'userTonality';
export const numberOfSuggestionsField = 'nTextItems';
export const lengthField = 'length';

export type EditBriefValues = {
  [outputTypeField]: string;
  [textField]: string;
  [keywords2Field]: string;
  [keywordsField]: string;
  [systemTonalityField]: SystemTonality[];
  [userTonalityField]: string[];
  [numberOfSuggestionsField]: number;
  [lengthField]: TextLength | null;
};

export const EditBriefSubStep = () => {
  const tr = useTr();
  const dispatch = useAppDispatch();
  const showOutputTypeModal = useShowOutputTypeModal();

  const { currentModelLanguage, currentModelCountry } = useAppSelector(
    getCurrentModelLanguageAndCountry
  );
  const isGenerateConfigReady = useAppSelector(getIsGenerateConfigReady);
  const isAiWriterTourRunning = useAppSelector(getIsAiWriterTourRunning);

  const initialValues = useInitialBriefValues();
  const onOutputTypeChange = useHandleBriefOutputTypeChange();

  const completeTextTypeTask = useCompleteTask(TASK_TYPE.USE_TEXT_TYPE);

  const isBrandHubEnabled = useFeatureFlagEnabled('new-brand-hub-in-sidebar');

  const validationSchema = useMemo(
    () =>
      mkBriefFormValidationSchema({
        textField,
        keywordsField,
        keywords2Field,
        numberOfSuggestionsField,
        tr
      }),
    [tr]
  );

  const { shouldShowRunOutOfWordsModal } = useShouldShowWordsLimitReachedModal();
  const onlyShowModal = useShowWordsLimitReachedModal();

  const onSubmit = (values: EditBriefValues) => {
    dispatch(changeGenerateTextConfig(values));
    dispatch(setInspirationsCurrentStep({ step: AiWriterSidebarStep.aiWriter, subStep: 'main' }));
    if (shouldShowRunOutOfWordsModal()) {
      onlyShowModal();
      return;
    }
    dispatch(generateTextInspirationsThunk());

    completeTextTypeTask();

    if (isAiWriterTourRunning) {
      dispatch(setIsAiWriterTourFinished(true));
    }
  };

  const languageAndCountryCode = `${currentModelLanguage}_${currentModelCountry}`;
  const tonalitySupportedInLanguage =
    isAiWriterSupportedLanguageAndCountryCode(languageAndCountryCode);

  const isTextLengthSupported = (outputType: string) =>
    outputTypesForTextLength.includes(outputType);

  const handleModalOutputTypeButton = (
    setFieldValue: (field: string, value: string, shouldValidate?: boolean | undefined) => void
  ) =>
    showOutputTypeModal({
      modelCountry: currentModelCountry,
      modelLanguage: currentModelLanguage,
      onOutputTypeChange: value => {
        onOutputTypeChange(value, setFieldValue);
        setFieldValue(outputTypeField, value);
      }
    });

  return (
    <AiWriterSidebarPanel
      title="aiWriter.inspirations.aiwriter.title"
      infoButton={<TextInspirationsStepHeader />}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        validateOnBlur={true}
        validateOnChange={true}
        enableReinitialize={true}
      >
        {({ values: { outputType, systemTonality, userTonality }, isValid, setFieldValue }) => (
          <Form>
            <Root>
              <FlexContainer>
                <EnhancedOutputTypeAutocomplete
                  name={outputTypeField}
                  modelLanguage={currentModelLanguage}
                  modelCountry={currentModelCountry}
                  onSelect={value => onOutputTypeChange(value, setFieldValue)}
                  onShowModal={() => handleModalOutputTypeButton(setFieldValue)}
                />
                <TextTypesButton onSelect={() => handleModalOutputTypeButton(setFieldValue)} />
              </FlexContainer>
              <BriefField
                name={textField}
                modelLanguage={currentModelLanguage}
                modelCountry={currentModelCountry}
                outputType={outputType}
              />
              <TourGroup $isAiWriterTourRunning={isAiWriterTourRunning}>
                <KeywordsField
                  name={keywordsField}
                  type="keywords"
                  modelLanguage={currentModelLanguage}
                  modelCountry={currentModelCountry}
                  outputType={outputType}
                />
              </TourGroup>
              <KeywordsField
                name={keywords2Field}
                type="keywords2"
                modelLanguage={currentModelLanguage}
                modelCountry={currentModelCountry}
                outputType={outputType}
              />
              <AdvancedSettingsButton>
                {isBrandHubEnabled ? (
                  <ProjectBrandVoiceAutocomplete
                    currentLanguage={currentModelLanguage}
                    currentCountry={currentModelCountry}
                  />
                ) : (
                  <ProjectPersonalitySelection />
                )}

                <ProjectInformationSelection />

                {tonalitySupportedInLanguage && (
                  <TonalitySelect
                    systemTonality={systemTonality}
                    userTonality={userTonality}
                    maxLength={tonalityMaxLength}
                    languageAndCountryCode={languageAndCountryCode}
                    onChange={(newSystemTonalities, newUserTonalities) => {
                      setFieldValue(systemTonalityField, newSystemTonalities);
                      setFieldValue(userTonalityField, newUserTonalities);
                    }}
                    titleVariant="subtitle2"
                  />
                )}

                {isTextLengthSupported(outputType) && (
                  <FlexContainer direction="column" gap="one">
                    <Typography variant="subtitle2">
                      <FormattedMessage id="aiWriter.inspirations.aiwriter.main.text_length" />
                    </Typography>
                    <TextLengthChips onClick={val => setFieldValue(lengthField, val)} />
                  </FlexContainer>
                )}

                <FlexContainer direction="column" gap="one">
                  <Typography variant="subtitle2">
                    <FormattedMessage id="aiWriter.inspirations.aiwriter.main.number_of_suggestions" />
                  </Typography>
                  <NumberOfSuggestionsChips
                    numberOfSuggestions={maxTextItemCount}
                    onClick={val => setFieldValue(numberOfSuggestionsField, val)}
                  />
                </FlexContainer>
              </AdvancedSettingsButton>
              <FlexContainer
                direction="row"
                justifyContent={isGenerateConfigReady ? 'space-between' : 'flex-end'}
              >
                {isGenerateConfigReady && (
                  <CloseBriefButton
                    color="primary"
                    variant="text"
                    onClick={() =>
                      dispatch(
                        dispatch(
                          setInspirationsCurrentStep({
                            step: AiWriterSidebarStep.aiWriter,
                            subStep: 'main'
                          })
                        )
                      )
                    }
                  >
                    <FormattedMessage id="aiWriter.inspirations.aiwriter.main.close_brief" />
                  </CloseBriefButton>
                )}
                <SubmitButton
                  id={gtmIds.aiWriter.confirmBrief}
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={!isValid}
                >
                  <FormattedMessage id="common.create" />
                </SubmitButton>
              </FlexContainer>
            </Root>
          </Form>
        )}
      </Formik>
    </AiWriterSidebarPanel>
  );
};

const SubmitButton = styled(Button)``;

const CloseBriefButton = styled(Button)``;

const TourGroup = styled.div<{ $isAiWriterTourRunning: boolean }>`
  ${({ $isAiWriterTourRunning }) => $isAiWriterTourRunning && wiggleAnimation}
`;

export const SuggestionsNumberInput = styled(TextField)`
  max-width: 4rem;
`;

const Root = styled(FlexContainer).attrs({ gap: 'four' })`
  padding-bottom: ${({ theme }) => theme.spacings.three};
`;
