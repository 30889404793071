import { useModal } from 'components/modals';
import { ModalType } from 'components/modals/modalComponents';
import { ModalProps } from 'components/modals/types';
import Toast from 'components/toasts/Toast';
import { getRouteUrl } from 'config/routes';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useRequestIdHeaderStore } from 'services/backofficeIntegration/http/client/useRequestIdHeaderStore';

type ReceivedNavigationMessage = {
  type: 'nav';
  path: string;
};

type ReceivedWindowSizeMessage = {
  type: 'size';
  width: number;
  height: number;
};

type ReceivedModalMessage = {
  type: 'show-modal';
  modalId: ModalType;
  options?: ModalProps;
};

type ReceivedPathChangeMessage = {
  type: 'path-change';
  path: string;
};

type ReceivedRequestIdHeaderMessage = {
  type: 'set-request-id';
  requestId: string;
};

type ReceivedToastErrorCommonMessage = {
  type: 'show-simple-toast-message';
  toastType: 'error' | 'success' | 'info' | 'warning';
  messageId?: string;
  messageValues?: Record<string, string | number>;
};

type ReceivedIframeMessage =
  | ReceivedNavigationMessage
  | ReceivedWindowSizeMessage
  | ReceivedPathChangeMessage
  | ReceivedModalMessage
  | ReceivedRequestIdHeaderMessage
  | ReceivedToastErrorCommonMessage;

export function useIframeMessageListener() {
  const navigate = useNavigate();
  const { showModal } = useModal();
  const setRequestId = useRequestIdHeaderStore(state => state.setRequestIdHeader);

  const brandHubBasePath = getRouteUrl('brandHubV2');

  useEffect(() => {
    const onEventMessage = (evt: MessageEvent) => {
      // TODO not working on local
      // if (evt.origin !== brandHubIframeUrl) {
      //   return;
      // }

      const data = evt.data as ReceivedIframeMessage;

      if (data.type === 'nav') {
        navigate(data.path);
      }

      if (data.type === 'path-change') {
        // This changes the URL in the browser, but does not reload the page
        window.history.replaceState(undefined, '', data.path);
      }

      if (data.type === 'show-modal') {
        showModal(data.modalId, data?.options);
      }

      if (data.type === 'size') {
        // if needed, set the size of the iframe
      }

      if (data.type === 'set-request-id') {
        setRequestId(data.requestId);
      }

      if (data.type === 'show-simple-toast-message') {
        if (data.messageId) {
          Toast[data.toastType](data.messageId, data.messageValues);
        } else {
          Toast.commonError();
        }
      }
    };

    window.addEventListener('message', onEventMessage);
    return () => {
      window.removeEventListener('message', onEventMessage);
    };
  }, [brandHubBasePath, navigate, setRequestId, showModal]);
}
