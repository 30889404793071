import { useQuery } from '@tanstack/react-query';
import { PersonalityCommonSelector } from 'components/personality/PersonalityCommonSelector';
import { PersonalityOrBrandVoiceHeaderWrapper } from 'components/personality/PersonalityOrBrandVoiceHeaderWrapper';
import { useSetDefaultChatPersonality } from 'features/aiWriter/AiWriterSidebar/steps/chat/useSetDefaultPersonality';
import { useField } from 'formik';
import { useState } from 'react';
import { PersonalityDto } from 'services/backofficeIntegration/http/dtos/PersonalityDto';
import { httpGetDefaultPersonality } from 'services/backofficeIntegration/http/endpoints/personalities/httpGetDefaultPersonality';
import { httpGetPersonality } from 'services/backofficeIntegration/http/endpoints/personalities/httpGetPersonality';

type Props = {
  name: string;
  modelId: string;
  modelLanguage: string;
  modelCountry: string;
};

export function PersonalityField(props: Props) {
  const [field, , helpers] = useField<number | undefined | null>(props.name);
  const value = field.value;

  const [personality, setPersonality] = useState<PersonalityDto | undefined | null>();

  const params = { language: props.modelLanguage, country: props.modelCountry };
  const defaultPersonalityQuery = useQuery({
    queryKey: httpGetDefaultPersonality.makeQueryKey(params),
    queryFn: () => httpGetDefaultPersonality.callEndpoint(params),
    onSuccess: data => {
      if (data && !value) {
        setPersonality(data);
        helpers.setValue(data.id);
      }
    }
  });

  useQuery({
    enabled: !!value,
    queryKey: value ? httpGetPersonality.makeQueryKey({ id: value }) : [],
    queryFn: value ? () => httpGetPersonality.callEndpoint({ id: value }) : () => null,
    onSuccess: data => {
      if (data) {
        setPersonality(data);
        helpers.setValue(data.id);
      }
    }
  });

  const { mutate: setDefaultPersonality } = useSetDefaultChatPersonality();

  const handleChange = (personality: PersonalityDto | undefined | null) => {
    setPersonality(personality);
    helpers.setValue(personality?.id);

    if (personality) {
      setDefaultPersonality({
        personalityId: personality.id,
        country: personality.country,
        language: personality.language
      });
    }
  };

  const isLoading = defaultPersonalityQuery.isLoading;
  return (
    <PersonalityOrBrandVoiceHeaderWrapper>
      <PersonalityCommonSelector
        value={personality}
        onChange={handleChange}
        preselectedModelId={props.modelId}
        isPropsLoading={isLoading}
      />
    </PersonalityOrBrandVoiceHeaderWrapper>
  );
}
