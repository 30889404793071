import { PersonalityCommonSelector } from 'components/personality/PersonalityCommonSelector';
import { PersonalityOrBrandVoiceHeaderWrapper } from 'components/personality/PersonalityOrBrandVoiceHeaderWrapper';
import { useChatCurrentGptModel } from 'features/aiWriter/AiWriterSidebar/steps/chat/chatStore';
import { useDocumentPersonalityData } from 'features/aiWriter/AiWriterSidebar/steps/chat/useDocumentPersonalityData';
import { useResetChat } from 'features/aiWriter/AiWriterSidebar/steps/chat/useResetChat';
import { useWarnAboutChatReset } from 'features/aiWriter/AiWriterSidebar/steps/chat/useShowChatResetModal';
import { useUpdateAiWriterAndDefaultPersonality } from 'features/aiWriter/AiWriterSidebar/steps/chat/useUpdateAiWriterAndDefaultPersonality';
import { getActiveTab } from 'features/aiWriter/store/selectors';
import { useGetDefaultBrandVoice } from 'features/brand-voice/useGetDefaultBrandVoice';
import { getEmbeddingModelDataSelector } from 'features/embeddingModels/store/selectors';
import { PersonalityDto } from 'services/backofficeIntegration/http/dtos/PersonalityDto';
import { GAEvents } from 'services/tracking/GAEvents';
import { useAppSelector } from 'store/hooks';

export const ProjectPersonalitySelection = () => {
  const updateDocumentPersonality = useUpdateAiWriterAndDefaultPersonality();
  const resetChat = useResetChat();
  const warnAboutChatReset = useWarnAboutChatReset();
  const { data: defaultBrandVoice } = useGetDefaultBrandVoice();

  const tab = useAppSelector(getActiveTab);

  const { isFetching, data: personalityData } = useDocumentPersonalityData();

  const getModelById = useAppSelector(getEmbeddingModelDataSelector);
  const model = getModelById(tab.embeddingModelId);

  const gptModel = useChatCurrentGptModel();

  const handlePersonalityChange = (personality: PersonalityDto | null | undefined) => {
    if (personality === undefined) {
      return;
    }

    warnAboutChatReset({
      onAccept: () => {
        resetChat({
          personalityId: personality?.id ?? null,
          brandVoiceId: defaultBrandVoice?.brandVoiceId ?? null,
          gptModel,
          callback: () => updateDocumentPersonality({ personality })
        });
      }
    });
  };

  return (
    <PersonalityOrBrandVoiceHeaderWrapper>
      <PersonalityCommonSelector
        fullWidth={true}
        value={personalityData ?? null}
        onChange={handlePersonalityChange}
        onClick={() => GAEvents.overviewPersonalitySelectOpen()}
        preselectedModelId={model.id}
        isPropsLoading={isFetching}
      />
    </PersonalityOrBrandVoiceHeaderWrapper>
  );
};
