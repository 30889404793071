import { StopCircleRounded } from '@mui/icons-material';
import { IconButton, InputAdornment, SelectChangeEvent } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { ExtendedPromptInput } from 'components/ExtendedPromptInput';
import LocalStorageKey from 'config/localStorageKey';
import { ChatPersonalitySelection } from 'features/aiWriter/AiWriterSidebar/steps/chat/ChatPersonalitySelection';
import {
  newMessageDraft,
  useChatCurrentGptModel,
  useIsChatEmpty,
  useStoredActiveConversationId,
  useStoredBrandVoiceId,
  useStoredPersonalityId
} from 'features/aiWriter/AiWriterSidebar/steps/chat/chatStore';
import { CommandTemplatesButton } from 'features/aiWriter/AiWriterSidebar/steps/chat/CommandTemplatesButton';
import { useMessageStreamingIndicatorStore } from 'features/aiWriter/AiWriterSidebar/steps/chat/messageStreamingIndicatorStore';
import { SidebarExtendedChatBrandVoiceSelect } from 'features/aiWriter/AiWriterSidebar/steps/chat/SidebarExtendedChatBrandVoiceSelect';
import { useResetConversationMutation } from 'features/aiWriter/AiWriterSidebar/steps/chat/useResetConversationMutation';
import { useWarnAboutChatReset } from 'features/aiWriter/AiWriterSidebar/steps/chat/useShowChatResetModal';
import {
  ChatOptimizePromptButton,
  IMPROVE_PROMPT_OUTPUT_TYPE
} from 'features/aiWriter/chat/ChatOptimizePromptButton';
import { GptSelect } from 'features/aiWriter/chat/GptSelect';
import { useIsGpt4Disabled } from 'features/aiWriter/chat/useIsGpt4Disabled';
import { useShowTemplatesModal } from 'features/aiWriter/commandTemplates/useShowTemplatesModal';
import { useUpdateCommandTemplateUsage } from 'features/aiWriter/commandTemplates/useUpdateCommandTemplateUsage';
import { useGetProjectOrPreselectedLanguageAndCountry } from 'features/aiWriter/hooks/useGetProjectOrPreselectedLanguageAndCountry';
import { getActiveTab } from 'features/aiWriter/store/selectors';
import { InformationButton } from 'features/information/apply-information/InformationButton';
import { usePromptOptimizerControlled } from 'features/textGenerator/promptOptimizer/usePromptOptimizerContorlled';
import { trackingWrapper } from 'features/tracking/wrapper/TrackingWrapper';
import useUpdateSubscriptionModal from 'features/updateSubscriptionModal/hook/useUpdateSubscriptionModal';
import { useFeatureFlagEnabled, usePostHog } from 'posthog-js/react';
import { ChangeEvent, KeyboardEvent, useState } from 'react';
import {
  GPT_MODELS,
  GptModel
} from 'services/backofficeIntegration/http/endpoints/aiWriter/httpCreateConversation';
import { httpStopStreamMessage } from 'services/backofficeIntegration/http/endpoints/aiWriter/httpStopStreamMessage';
import { ChatMessageSource, GAEvents } from 'services/tracking/GAEvents';
import gtmIds from 'services/tracking/GTMIds';
import { useAppSelector } from 'store/hooks';
import styled from 'styled-components';
import { useLocalStorage } from 'utils/hooks/useLocalStorage';
import useTr from 'utils/hooks/useTr';
import { withTestId } from 'utils/utils';

export type ChatMessageInput = {
  isSending: boolean;
  value: string;
  sendButtonConfig: { gtmId: string; disabled?: boolean };
  onChange: (message: string) => void;
  onSend: () => void;
};

export function ChatMessageInput({ isSending, value, onChange, onSend }: ChatMessageInput) {
  const translate = useTr();

  const currentTab = useAppSelector(getActiveTab);

  const activeConversationId = useStoredActiveConversationId();
  const personalityId = useStoredPersonalityId();
  const brandVoiceId = useStoredBrandVoiceId();
  const isChatEmpty = useIsChatEmpty();

  const { mutateAsync: resetConversation } = useResetConversationMutation();
  const warnAboutChatReset = useWarnAboutChatReset();

  const showTemplatesModal = useShowTemplatesModal();
  const { mutate: updateCommandTemplateUsage } = useUpdateCommandTemplateUsage();

  const { mutate: stopStreamMessage, isLoading: isStopStreamMessageLoading } = useMutation({
    mutationFn: httpStopStreamMessage.callEndpoint
  });

  const isMessageBeingStreamed = useMessageStreamingIndicatorStore(
    state => state.isMessageBeingStreamed
  );

  const postHog = usePostHog();

  const currentGptModel = useChatCurrentGptModel();

  const [storedGptModel, setStoredGptModel] = useLocalStorage<GptModel>(
    LocalStorageKey.DefaultGptModel,
    GPT_MODELS.GPT_3_5,
    false
  );

  const initGptModel = currentGptModel ?? storedGptModel ?? GPT_MODELS.GPT_3_5;

  const [gptModel, setGptModel] = useState<GptModel>(initGptModel);

  const showUpgradeSubscriptionModal = useUpdateSubscriptionModal();

  const isGpt4Disabled = useIsGpt4Disabled();

  const isBrandHubEnabled = useFeatureFlagEnabled('new-brand-hub-in-sidebar');

  const handleGptModelChange = (event: SelectChangeEvent<unknown>) => {
    if (isGpt4Disabled) {
      showUpgradeSubscriptionModal();
      return;
    }
    const newModel = event.target.value as GptModel;
    // if upgrade option is selected it has no value therefore we do nothing here
    if (!newModel) {
      return;
    }

    if (activeConversationId && !isChatEmpty) {
      warnAboutChatReset({
        onAccept: () => {
          setGptModel(newModel);
          setStoredGptModel(newModel);
          resetConversation({ personalityId: personalityId, brandVoiceId, gptModel: newModel });
        }
      });
    } else {
      setGptModel(newModel);
      setStoredGptModel(newModel);
      resetConversation({ personalityId: personalityId ?? null, brandVoiceId, gptModel: newModel });
    }
  };

  function handleKeyDown(e: KeyboardEvent<HTMLTextAreaElement>) {
    if (!e.shiftKey && e.key === 'Enter') {
      e.preventDefault();

      if (isSending || isStopStreamMessageLoading) {
        return;
      }

      sendMessage({ source: 'enter' });
    }
  }

  function sendMessage({ source }: { source: ChatMessageSource }) {
    GAEvents.sentChatMessage({ source });
    postHog?.capture('CF - chat message sent via chat');
    onSend();
  }

  function handleSelectTemplate() {
    trackingWrapper.track('templatesOpenedViaChat');
    showTemplatesModal({
      preselectedModelId: currentTab.embeddingModelId,
      onTemplateSelect: dto => {
        trackingWrapper.track('templateSelected', {
          templateId: dto.id,
          templateLabel: dto.title,
          templateCountry: dto.country,
          templateLanguage: dto.language
        });

        updateCommandTemplateUsage({ templateId: dto.id });

        newMessageDraft({ text: dto.template, sendInstantly: true });
        GAEvents.promptInChatSent();
      }
    });
  }

  const handleStopStreamMessage = () => {
    stopStreamMessage({ conversationId: activeConversationId });
    GAEvents.stopStreamingClicked();
  };

  function selectAdornment() {
    if (isSending || isMessageBeingStreamed) {
      return (
        <IconButton onClick={handleStopStreamMessage} disabled={!activeConversationId}>
          <StopCircleRounded />
        </IconButton>
      );
    }

    return null;
  }

  const handleTextFieldChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    onChange(e.currentTarget.value);

    if (isUndoVisible) {
      setIsUndoVisible(false);
    }
  };

  const { language, country } = useGetProjectOrPreselectedLanguageAndCountry();

  const {
    isUndoVisible,
    setInitialPrompt,
    optimizePromptMutation,
    undoPromptOptimization,
    setIsUndoVisible
  } = usePromptOptimizerControlled({
    prompt: value,
    setPrompt: (prompt: string) => onChange(prompt),
    locale: { language, country },
    initialPrompt: '',
    outputType: IMPROVE_PROMPT_OUTPUT_TYPE,
    callback: () => trackingWrapper.track('promptOptimized', { prompt: value })
  });
  const { mutate: optimizePrompt, isLoading: isOptimizationLoading } = optimizePromptMutation;

  const handleOptimizeClick = () => {
    setInitialPrompt(value);
    optimizePrompt();
  };

  return (
    <StyledChatMessageInput
      {...withTestId('chat-message-input')}
      value={value}
      onChange={handleTextFieldChange}
      onKeyDown={handleKeyDown}
      aria-label={translate('chat.message_input.aria_label')}
      placeholder={translate('chat.message_input.placeholder')}
      textAreaAdornment={
        <StyledInputAdornment position="end">{selectAdornment()}</StyledInputAdornment>
      }
      actions={
        <ActionsWrapper>
          <ActionBox>
            {isBrandHubEnabled ? (
              <SidebarExtendedChatBrandVoiceSelect />
            ) : (
              <ChatPersonalitySelection />
            )}

            <Separator />

            <InformationButton />

            <Separator />

            <GptSelect
              value={gptModel}
              onChange={handleGptModelChange}
              isGpt4Disabled={isGpt4Disabled}
            />

            <Separator />

            <CommandTemplatesButton
              gtmId={gtmIds.aiWriter.chat.openTemplatesLibrary}
              onSelection={handleSelectTemplate}
            />
          </ActionBox>

          <ChatOptimizePromptButton
            prompt={value}
            undoVisible={isUndoVisible}
            loading={isOptimizationLoading}
            onOptimizeClick={handleOptimizeClick}
            onUndoClick={undoPromptOptimization}
            isInChat={true}
          />
        </ActionsWrapper>
      }
    />
  );
}

const Separator = styled.div`
  background-color: ${({ theme }) => theme.colors.inputBackground};
  height: 19px;
  width: 1px;
  margin: 0 5px;
`;

const ActionBox = styled.div`
  display: flex;
  align-items: center;
`;

const StyledInputAdornment = styled(InputAdornment)`
  position: absolute;
  right: 4px;
  bottom: 72px;
`;

const ActionsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-height: 30px;
`;

const StyledChatMessageInput = styled(ExtendedPromptInput)`
  font-size: 0.875rem;
`;
