import Toast from 'components/toasts/Toast';
import { getErrorCodeFromAxiosError } from 'features/teamInvitation/getErrorCodeFromAxiosError';
import { handleGenerateTextErrorsWithHyperlink } from 'services/api/wordEmbedding/errors';
import { BaseSuccessDto } from 'services/backofficeIntegration/http/BaseSuccessDto';
import { backofficeClient } from 'services/backofficeIntegration/http/client/createBackofficeClient';
import { mutationEndpoint } from 'services/backofficeIntegration/http/endpointDefinition';

type CreateConversationDto = {
  conversation_id: string;
  personality_id: number | null;
};

type SuccessDto = BaseSuccessDto<CreateConversationDto>;

export const GPT_MODELS = {
  GPT_3_5: 'gpt-3.5-turbo',
  GPT_4: 'gpt-4'
} as const;

export type GptModel = typeof GPT_MODELS[keyof typeof GPT_MODELS];

export type CreateConversationProps = {
  projectId: string;
  conversationId: string;
  personalityId: number | null;
  brandVoiceId: string | null;
  gptModel: GptModel | null;
};

export const httpCreateConversation = mutationEndpoint({
  callEndpoint: (props: CreateConversationProps) => {
    const { projectId, conversationId, personalityId, brandVoiceId, gptModel } = props;
    return backofficeClient
      .put<SuccessDto>(`/projects/ai-writer/${projectId}/conversations`, {
        conversation_id: conversationId,
        personality_id: personalityId,
        brand_voice_id: brandVoiceId,
        current_model: gptModel
      })
      .then(response => {
        return response.data.data;
      })
      .catch(error => {
        const errorCode = getErrorCodeFromAxiosError(error);
        if (errorCode) {
          if (errorCode === 'ERROR_GPT4_ACCESS_DISABLED') {
            Toast.error(`aiWriter.chat.error.${errorCode}`);
            return;
          }

          Toast.backendError(...handleGenerateTextErrorsWithHyperlink(errorCode));
          return;
        }
      });
  }
});
