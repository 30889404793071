import { LoadingAnimationWithAlternatingMessages } from 'features/loading-spinner/LoadingAnimationWithAlternatingMessages';
import { OnboardingSurveyOld } from 'features/onboardingJourney/new-onboarding-2025/old-components/onboarding-survey-old';
import { OnboardingSurveyNew } from 'features/onboardingJourney/new-onboarding-2025/onboarding-survey-new';
import { useFeatureFlagEnabled } from 'posthog-js/react';

export function OnboardingSurvey() {
  const isBrandHubEnabled = useFeatureFlagEnabled('new-brand-hub-in-sidebar');

  // When the feature flag is still loading
  if (isBrandHubEnabled === undefined) {
    return <LoadingAnimationWithAlternatingMessages />;
  }

  return isBrandHubEnabled ? <OnboardingSurveyNew /> : <OnboardingSurveyOld />;
}
