import { blogPostWorkflowOutputTypes } from 'features/aiWriter/AiWriterSidebar/steps/textInspirations/stepOutputTypes';
import { initializeBlogPostBuilderThunk } from 'features/aiWriter/store/actions/tabs/thunks/initializeBlogPostBuilderThunk';
import { unnamed } from 'features/aiWriter/utils/unnamed';
import { getGetAudienceByLanguageAndCountry } from 'features/audiences/store/selectors';
import { useGetAllDefaultBrandVoices } from 'features/brand-voice/useGetDefaultBrandVoice';
import { getGetEmbeddingModelById } from 'features/embeddingModels/store/selectors';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { PersonalityDto } from 'services/backofficeIntegration/http/dtos/PersonalityDto';
import { httpGetDefaultPersonality } from 'services/backofficeIntegration/http/endpoints/personalities/httpGetDefaultPersonality';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { assertNonNullable } from 'utils/typescript/nonNullable';

export const useCreateDocument = () => {
  const getWordEmbeddingModel = useSelector(getGetEmbeddingModelById);
  const getAudiences = useAppSelector(getGetAudienceByLanguageAndCountry);
  const dispatch = useAppDispatch();

  const isBrandHubEnabled = useFeatureFlagEnabled('new-brand-hub-in-sidebar');
  const { refetch: fetchBrandVoice } = useGetAllDefaultBrandVoices();

  const createDocument = useCallback(
    async (embeddingModelId: string, personality?: PersonalityDto) => {
      const wordEmbeddingModel = getWordEmbeddingModel(embeddingModelId);
      assertNonNullable(wordEmbeddingModel, 'Model not found');

      const audiences = getAudiences(wordEmbeddingModel.language, wordEmbeddingModel.country);
      const audienceId = audiences[0].id;

      const defaultPersonalityResult = await httpGetDefaultPersonality.callEndpoint({
        language: wordEmbeddingModel.language,
        country: wordEmbeddingModel.country
      });

      // Note: We HAVE to check this here additionally even if the useQuery in useGetAllDefaultBrandVoices()
      //       checks this in enabled-property, because refetch() doesn't check it
      const brandVoiceResult = isBrandHubEnabled ? await fetchBrandVoice() : null;
      const defaultBrandVoice = brandVoiceResult?.data?.find(
        voice =>
          voice.language === wordEmbeddingModel.language &&
          voice.country === wordEmbeddingModel.country
      );

      dispatch(
        initializeBlogPostBuilderThunk(
          {
            audienceId,
            outputType: blogPostWorkflowOutputTypes.title,
            embeddingModelId,
            name: unnamed,
            isNewDocument: true,
            brief: '',
            keywords: '',
            keywords2: '',
            tonality: [],
            personalityId: personality?.id ?? defaultPersonalityResult?.id ?? undefined,
            brandVoiceId: defaultBrandVoice?.brandVoiceId
          },
          { shouldCollapseSidebar: false }
        )
      );
    },
    [getWordEmbeddingModel, getAudiences, isBrandHubEnabled, fetchBrandVoice, dispatch]
  );

  return { createDocument };
};
