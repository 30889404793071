import { TElement } from '@udecode/plate-common';
import { ELEMENT_H2 } from '@udecode/plate-heading';
import { ELEMENT_IMAGE } from '@udecode/plate-media';
import { ELEMENT_PARAGRAPH } from '@udecode/plate-paragraph';
import Toast from 'components/toasts/Toast';
import { blogPostWorkflowOutputTypes } from 'features/aiWriter/AiWriterSidebar/steps/textInspirations/stepOutputTypes';
import { getGenerateTextConfig } from 'features/aiWriter/store/selectors';
import useAudienceConfig from 'features/audiences/hooks/useAudienceConfig';
import { getEmbeddingModelDataSelector } from 'features/embeddingModels/store/selectors';
import { generateTextSafely } from 'features/textGenerator/actions/generateTexts';
import { invalidateWordsUsageQueries } from 'features/wordsUsage/invalidateWordsUsageQueries';
import { removeNumberingFromHeadline } from 'features/workflowBlogPostBuilder/removeNumberingFromHeadline';
import UnsplashAPI from 'services/api/unsplash';
import { invalidateUnsplashImagesUsageQueries } from 'services/api/unsplash/invalidateUnsplashImagesUsageQueries';
import { handleCommonApiError } from 'services/api/utils/handleCommonApiError';
import { invalidateCustomerAllLimitationsQueries } from 'services/backofficeIntegration/http/endpoints/customer/httpGetAllLimitations';
import { InformationDto } from 'services/backofficeIntegration/http/endpoints/infomration/httpGetInformationList';
import { useAppSelector } from 'store/hooks';

export const useGenerateBlogPostContent = () => {
  const { audienceId } = useAppSelector(getGenerateTextConfig);
  const { audienceModelId } = useAudienceConfig({ audienceId });
  const getModelById = useAppSelector(getEmbeddingModelDataSelector);

  return (options: GenerationOptions) =>
    generateContent({
      audienceModelId,
      language: getModelById(options.modelId).language,
      ...options
    });
};

type GeneratorResult =
  | {
      type: 'intro';
      paragraphText: string;
    }
  | {
      type: 'section';
      sectionHeadline: string;
      paragraphText: string;
    }
  | {
      type: 'image';
      paragraphText: string; // actually it's an image url but its temporary so I didn't wanted to complicate it
    };

type GenerationOptions = {
  title: string;
  sectionHeadlines: string[];
  systemTonality: string[];
  userTonality: string[];
  imageConfig: ImageConfig | null;
  modelId: string;
  personalityId: number | null;
  brandVoiceId?: string;
  informationList?: InformationDto[];
};

type InternalOptions = {
  audienceModelId: string;
  language: string;
};

type ImageConfig = {
  query: string;
};

type GenerateContentOptions = GenerationOptions & InternalOptions;

async function generateContent({
  audienceModelId,
  title,
  sectionHeadlines,
  systemTonality,
  userTonality,
  imageConfig,
  language,
  personalityId,
  brandVoiceId,
  informationList
}: GenerateContentOptions) {
  // If you ever change this, make sure to update also createEditorNodes() which expects a specific order
  const allContentRequests: Promise<GeneratorResult>[] = [
    getIntroRequestPromise(),
    getImagesRequestPromise(),
    ...sectionHeadlines.map(getSectionRequestPromise)
  ];

  // Order of results corresponds to the order in the array not the order the promises are resolved
  const allResults = await Promise.all([...allContentRequests]);

  invalidateWordsUsageQueries();
  invalidateUnsplashImagesUsageQueries();
  invalidateCustomerAllLimitationsQueries();

  return createEditorNodes(allResults);

  function getImagesRequestPromise(): Promise<GeneratorResult> {
    const emptyImage: GeneratorResult = {
      type: 'intro',
      paragraphText: ''
    };

    return imageConfig
      ? UnsplashAPI.get({ ...imageConfig, lang: language, per_page: 1 }).then(response => {
          if (response.status) {
            return {
              type: 'image',
              paragraphText: response.data.results[0]?.urls.full ?? ''
            };
          } else {
            Toast.backendError(handleCommonApiError(response.message));
            return emptyImage;
          }
        })
      : new Promise(resolve => resolve(emptyImage));
  }

  function getIntroRequestPromise(): Promise<GeneratorResult> {
    const emptyParagraph: GeneratorResult = {
      type: 'intro',
      paragraphText: ''
    };

    return generateTextSafely({
      outputType: blogPostWorkflowOutputTypes.intro,
      text: title,
      keywords: sectionHeadlines.join('\n'),
      audienceModelId,
      systemTonality,
      userTonality,
      personalityId,
      brandVoiceId,
      informationList
    })
      .then(
        (paragraphText): GeneratorResult => ({
          type: 'intro',
          paragraphText: paragraphText ?? ''
        })
      )
      .catch(() => emptyParagraph);
  }

  function getSectionRequestPromise(sectionHeadline: string): Promise<GeneratorResult> {
    const contentSectionWithoutParagraph: GeneratorResult = {
      type: 'section',
      sectionHeadline,
      paragraphText: ''
    };

    const cleanedLine = removeNumberingFromHeadline(sectionHeadline);

    return generateTextSafely({
      outputType: blogPostWorkflowOutputTypes.paragraph,
      text: cleanedLine,
      keywords: title,
      keywords2: sectionHeadlines.join('\n'),
      audienceModelId,
      systemTonality,
      userTonality,
      personalityId,
      brandVoiceId,
      informationList
    })
      .then(
        (paragraphText): GeneratorResult => ({
          type: 'section',
          sectionHeadline,
          paragraphText: paragraphText ?? ''
        })
      )
      .catch(() => contentSectionWithoutParagraph);
  }
}

export function createEditorNodes(results: GeneratorResult[]) {
  const editorNodes = new Array<TElement>();

  for (const result of results) {
    const { paragraphText } = result;

    if (result.type === 'image') {
      editorNodes.push({
        type: ELEMENT_IMAGE,
        url: paragraphText ?? '',
        children: [
          {
            text: ''
          }
        ]
      });

      continue;
    }

    // Currently all requests creating a paragraph
    // But each content section also contains a headline as first node
    if (result.type === 'section') {
      editorNodes.push({
        type: ELEMENT_H2,
        children: [{ text: result.sectionHeadline }]
      });
    }

    editorNodes.push({
      type: ELEMENT_PARAGRAPH,
      children: [{ text: paragraphText }]
    });
  }

  return editorNodes;
}
