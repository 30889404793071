import AuthRoute from 'components/auth/AuthRoute';
import { routerRoutes } from 'config/routerRoutes';
import {
  getRoutePath,
  getRouteUrl,
  RouteName,
  routesConfig,
  shouldPageBeVisible
} from 'config/routes';
import {
  getCustomerRole,
  getCustomerTeams,
  getIsApiCustomer
} from 'features/customer/store/selectors';
import { LoginPage } from 'pages/login/LoginPage';
import { NotFoundPage } from 'pages/NotFoundPage';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import { useMemo } from 'react';
import { Navigate, Route } from 'react-router-dom';
import { useAppSelector } from 'store/hooks';

export const useGetRoutes = () => {
  const customerGroupId = useAppSelector(state => state.customer.groupId);
  const userRole = useAppSelector(getCustomerRole);
  const isApiCustomer = useAppSelector(getIsApiCustomer);
  const customerTeams = useAppSelector(getCustomerTeams);

  const isBrandHubEnabled = useFeatureFlagEnabled('new-brand-hub-in-sidebar');

  const redirects = useMemo(
    () => [
      { from: '/bubble-graph', to: getRoutePath('exploring'), active: true },
      { from: '/concept-flash', to: getRoutePath('exploring'), active: true },
      { from: '/content-flash', to: getRoutePath('aiTester'), active: true },
      {
        from: '/brand-hub/overview/information',
        to: getRouteUrl('brandHubV2Knowledge'),
        active: isBrandHubEnabled
      },
      {
        from: '/brand-hub/overview/brand-voice',
        to: getRouteUrl('brandHubV2BrandVoice'),
        active: isBrandHubEnabled
      },
      {
        from: '/brand-hub/overview/workflows',
        to: getRouteUrl('brandHubV2Workflows'),
        active: isBrandHubEnabled
      },
      {
        from: '/brand-hub/overview/templates',
        // No dedicated URL for this page
        to: getRouteUrl('brandHubV2'),
        active: isBrandHubEnabled
      }
    ],
    [isBrandHubEnabled]
  );

  return useMemo(
    () =>
      [<Route path="/" key="/" element={<LoginPage />} />]
        .concat(
          Object.entries(routesConfig)
            .filter(([, config]) =>
              shouldPageBeVisible(config, customerGroupId, userRole, isApiCustomer, customerTeams)
            )
            // Remove routes from new brand hub if feature flag is disabled or remove
            // old brand hub routes if feature flag is enabled.
            // The last one is required to make the redirects working
            .filter(([, config]) => {
              if (config.path === routesConfig.brandHubV2.path) {
                return isBrandHubEnabled;
              }
              if (
                [
                  routesConfig.brandHubOverviewWorkflows.path,
                  // TODO: Disabled to allow creating old personalities and test legacy feature
                  //       Will be removed on PROD
                  //routesConfig.brandHubOverviewBrandVoice.path,
                  routesConfig.brandHubOverviewInformation.path,
                  routesConfig.brandHubOverviewTemplates.path
                ].includes(config.path)
              ) {
                return !isBrandHubEnabled;
              }

              return true;
            })
            .map(([route, config]) => {
              const RouteComponent = routerRoutes[route as RouteName];
              const routeElement = config.auth ? (
                <AuthRoute component={RouteComponent} routeName={route as RouteName} />
              ) : (
                <RouteComponent />
              );

              return <Route path={config.path} key={config.path} element={routeElement} />;
            })
        )
        .concat(
          redirects
            .filter(({ active }) => active)
            .map(({ from, to }) => (
              <Route key={`${from}_${to}`} path={from} element={<Navigate to={to} />} />
            ))
            .concat(<Route key="*" path="*" element={<NotFoundPage />} />)
        ),
    [redirects, customerGroupId, userRole, isApiCustomer, customerTeams, isBrandHubEnabled]
  );
};
