import { useInfiniteQuery } from '@tanstack/react-query';
import { useWorkspaceStore } from 'features/workspaces/useWorkspaceStore';
import {
  GetBrandVoicesParams,
  httpGetBrandVoicesList
} from 'services/backofficeIntegration/http/endpoints/new-brand-voice/httpGetBrandVoicesList';
import { ModelsBrandVoice } from 'services/backofficeIntegration/http/endpoints/personalities/httpCreateBrandVoice';
import { brandVoicesQueryKeys } from 'services/backofficeIntegration/http/queryKeyFactories/brandVoicesQueryKeys';
import { assertNonNullable } from 'utils/typescript/nonNullable';

type QueryOptions = Partial<
  Omit<GetBrandVoicesParams['body'], 'sort' | 'withRelations' | 'includeDefaultFirst'>
> & {
  sort?: 'most_recent' | 'updated_at';
};

export const useGetBrandVoicesInfinite = (queryOptions?: QueryOptions) => {
  const workspaceId = useWorkspaceStore(state => state.workspaceId);

  const bodyOptions: Omit<GetBrandVoicesParams['body'], 'page'> = {
    ...queryOptions,
    size: queryOptions?.size || 6,
    withRelations: true,
    // Make sure the default one is always part of the result
    includeDefaultFirst: true
  };

  const queryKey = [...brandVoicesQueryKeys.forList(), 'infinite', bodyOptions];

  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, ...rest } = useInfiniteQuery({
    enabled: !!workspaceId,
    queryKey,
    queryFn: async ({ pageParam = 1 }) => {
      assertNonNullable(workspaceId, 'workspaceId is null');

      const response = await httpGetBrandVoicesList.callEndpoint({
        workspaceId,
        body: {
          ...bodyOptions,
          page: pageParam
        }
      });

      if (!response.data) {
        throw new Error('Failed to fetch brand voices');
      }

      return response.data;
    },
    getNextPageParam: lastPage => {
      if (!lastPage.page) {
        return undefined;
      }

      const { currentPage = 1, totalPages = 1 } = lastPage.page;
      return currentPage < totalPages ? currentPage + 1 : undefined;
    }
  });

  const brandVoices =
    data?.pages?.reduce<ModelsBrandVoice[]>((acc, page) => acc.concat(page?.data || []), []) || [];

  const totalItems = data?.pages?.[0]?.page?.totalElements || 0;

  return {
    brandVoices,
    totalItems,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    ...rest
  };
};
